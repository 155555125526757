<template>
  <vs-card
    class="w-full sm:w-dashboard-card-tablet sm:h-dashboard-card-tablet lg:w-dashboard-card lg:h-dashboard-card border-solid border-2 border-gray-200 flex items-center justify-center"
  >
    <vc-calendar :attributes="[...startDateAttr, ...endDateAttr, ...expireDateAttr, ...currentDateAttr]" trim-weeks is-expanded />
  </vs-card>
</template>

<script>
import { Timestamp } from 'firebase/firestore';
export default {
  name: 'DashboardCalendar',
  props: {
    jobs: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },

  computed: {
    currentDateAttr() {
      return [
        {
          dates: new Date(),
          highlight: {
            class: 'start_date_circle',
          },
        },
      ];
    },
    startDateAttr() {
      return [
        ...Object.values(this.jobs).map((job) => ({
          dates: job.startDate && job.startDate instanceof Timestamp ? job.startDate.toDate() : null,
          dot: {
            color: 'green',
          },

          popover: {
            label: `${job.jobTitle} start date`,
            hideIndicator: true,
          },
          customData: job,
        })),
      ];
    },
    endDateAttr() {
      return [
        ...Object.values(this.jobs).map((job) => ({
          dates: job.endDate && job.endDate instanceof Timestamp ? job.endDate.toDate() : null,
          dot: {
            color: 'orange',
          },

          popover: {
            label: `${job.jobTitle} end date`,
            hideIndicator: true,
          },
          customData: job,
        })),
      ];
    },
    expireDateAttr() {
      return [
        ...Object.values(this.jobs).map((job) => ({
          dates: job.expire_at ? job.expire_at : null,
          dot: {
            color: 'red',
          },
          popover: {
            label: `${job.jobTitle} expires`,
            hideIndicator: true,
          },
          customData: job,
        })),
      ];
    },
  },
};
</script>

<style lang="scss">
.vc-container {
  border: none !important;
}
.start_date_circle {
  background: #adca63cb !important;
}
.end_date_circle {
  background: #ea545480 !important;
}
</style>
