<template>
  <div v-if="guideCounters" class="flex flex-grow flex-col lg:flex-row sm:flex-wrap justify-center">
    <div v-for="(item, index) in cards" :key="index">
      <!-- :active="selectedIndex === index" -->
      <!-- lg:mr-3 xl:mr-5 -->
      <DataCard :item="item" class="mb-3"></DataCard>
    </div>
  </div>
</template>

<script>
import DataCard from '@/components/data-cards/DataCard.vue';

export default {
  components: {
    DataCard,
  },

  computed: {
    guideCounters() {
      return this.$store.state.counterModule.data.guideTypeCount;
    },
    cards() {
      return [
        { name: 'Nature / Field Guides', stat: this.guideCounters.natureField, icon: 'UsersIcon' },
        { name: 'Culture / Tour Guides', stat: this.guideCounters.cultureTour, icon: 'UsersIcon' },
        { name: 'Adventure Guides', stat: this.guideCounters.adventure, icon: 'UsersIcon' },
        { name: 'Lodge Managers', stat: this.guideCounters.lodgeManager, icon: 'UsersIcon' },
        { name: 'Management Couples', stat: this.guideCounters.managementCouple, icon: 'UsersIcon' },
      ];
    },
  },
};
</script>
