<!-- <template>
  <dl>
    <div
      class="relative box-border border-solid border-2 bg-white pt-5 px-4 pb-5 sm:pt-6 sm:px-6 rounded-lg overflow-hidden"
      :class="[active ? 'border-theme-color ' : 'border-gray-200 ', { 'cursor-pointer': selectable }]"
      @click="selectable ? $emit('click') : null"
    >
      <dt>
        <div class="absolute bg-theme-color rounded-md p-3">
          <feather-icon :icon="item.icon" svg-classes="h-6 w-6" class="text-rag-green"></feather-icon>
        </div>
        <p class="ml-16 text-sm font-medium text-dark-grey truncate">{{ item.name }}</p>
      </dt>
      <dd class="ml-16 flex items-baseline">
        <p class="text-xl font-semibold">
          {{ item.stat }}
        </p>
      </dd>
    </div>
  </dl>
</template> -->

<template>
  <dl>
    <div
      class="bg-white pt-2 px-4 pb-2 sm:pt-6 sm:px-6 rounded-lg overflow-hidden"
      :class="[active ? 'border-theme-color ' : 'border-gray-200 ', { 'cursor-pointer': selectable }]"
      @click="selectable ? $emit('click') : null"
    >
      <dt class="flex flex-col justify-center items-center">
        <p class="text-xl text-rag-green font-semibold"> {{ item.stat }} </p>
        <p class="text-sm font-medium text-dark-grey truncate">{{ item.name }}</p>
      </dt>
    </div>
  </dl>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
    },
    selectable: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
