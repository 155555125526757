<template>
  <div id="data-list-thumb-view" class="w-full">
    <vs-card class="border-solid border-2 border-gray-200 sm:h-dashboard-card-tablet lg:h-dashboard-card overflow-y-auto pr-0">
      <div class="flex flex-col">
        <div class="">
          <div class="inline-block min-w-full py-1 align-middle pr-3 md:pr-0 md:pl-4">
            <table class="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" class="pl-4 pr-3 text-left text-lg font-semibold text-rag-green sm:pl-8 md:pl-8">Your Current Jobs</th>
                  <th scope="col" class="px-3 text-center text-sm font-semibold text-dark-grey">Applied</th>
                  <th scope="col" class="px-3 text-center text-sm font-semibold text-dark-grey">Shortlisted</th>
                  <th scope="col" class="px-3 text-center text-sm font-semibold text-dark-grey">Invited</th>
                  <th scope="col" class="px-3 text-center text-sm font-semibold text-dark-grey">Views</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(job, index) in orderedJobs" :key="index" class="">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-left text-base font-medium text-dark-grey base:pl-8 md:pl-8">{{
                    job.jobTitle
                  }}</td>
                  <td class="whitespace-nowrap text-center py-3 px-3 text-base text-gray-500">{{ statusNumbers(job.jobId, 0) }}</td>
                  <td class="whitespace-nowrap text-center py-3 px-3 text-base text-gray-500">{{ statusNumbers(job.jobId, 2) }}</td>
                  <td class="whitespace-nowrap text-center py-3 px-3 text-base text-gray-500">{{ statusNumbers(job.jobId, 1) }}</td>
                  <td class="whitespace-nowrap text-center py-3 px-3 text-base text-gray-500">{{ jobViews(job) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-if="orderedJobs.length === 0" class="h-64 w-full"
          ><p class="text-base text-center text-gray-400 relative mt-3 top-1/2">You have no active Job Posts</p></div
        >
      </div>
    </vs-card>
  </div>
</template>

<script>
export default {
  name: 'CurrentJobs',
  props: {
    jobs: {
      type: Object,
      required: true,
    },
    applications: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemsPerPage: 3,
    };
  },

  computed: {
    orderedJobs() {
      this.$store.dispatch('jobsModule/setJobList');
      let jobs = this.formatData(this.jobs);
      jobs.sort((x, y) => {
        let timeX = x.expire_at;
        let timeY = y.expire_at;
        return timeY - timeX;
      });
      return jobs;
    },
  },

  methods: {
    formatData(data) {
      let formattedData = Object.values(data).map(function (key) {
        const fields = key;
        let obj = {};
        for (const key of Object.keys(fields)) {
          obj[key] = fields[key];
        }
        return obj;
      });
      return formattedData;
    },
    statusNumbers(jobId, status) {
      const apps = Object.values(this.applications).filter((app) => {
        return app.jobId === jobId && app.status === status;
      });
      return apps.length;
    },
    jobViews(job) {
      if (!job.uniqueViews || job.uniqueViews.length === 0) return '--';
      return job.uniqueViews.length;
    },
  },
};
</script>
